import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import { Avatar, List, Skeleton, Typography, Tag, Button } from "antd";
import { getUser, getUserWorkflows, upgradeWorfklow } from "../../api/runalloy";

export const UserPage = () => {
  const { userId } = useParams();
  const queryClient = useQueryClient();

  const {
    isLoading: isUserLoading,
    isError: isUserError,
    error: userError,
    data: userData,
  } = useQuery({
    queryKey: ["user", userId],
    queryFn: async () => {
      const { data } = await getUser(userId);
      return data;
    },
  });

  const {
    isLoading: isWorkflowsLoading,
    isError: isWorkflowsError,
    error: workflowsError,
    data: workflowsData,
  } = useQuery({
    queryKey: ["workflows", userId],
    queryFn: async () => {
      const { data } = await getUserWorkflows(userId);
      return data;
    },
    enabled: !!userData?.userId,
  });

  const mutation = useMutation({
    mutationKey: ["upgradeWorkflow"],
    mutationFn: async ({ workflowId }) => {
      const { data } = await upgradeWorfklow(workflowId, userId);
      return data;
    },
    onSuccess: (_result, { workflowId }, _context) => {
      queryClient.setQueryData(["workflows", userId], ({ data }) => ({
        data: data.map((workflow) => {
          return workflow.workflowId === workflowId
            ? { ...workflow, installedVersion: workflow.version }
            : workflow;
        }),
      }));
    },
  });

  if (isUserLoading || isWorkflowsLoading) return <Skeleton />;
  if (isUserError) return <div>{userError.message}</div>;
  if (isWorkflowsError) return <div>{workflowsError.message}</div>;

  return (
    <>
      <Typography.Title level={3}>
        {userData.fullName} ({userData.userId})
      </Typography.Title>
      <List
        className="demo-loadmore-list"
        loading={isWorkflowsLoading}
        itemLayout="horizontal"
        dataSource={workflowsData.data}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Button
                disabled={
                  !item.installed || item.version === item.installedVersion
                }
                onClick={() => mutation.mutate({ workflowId: item.workflowId })}
              >
                Upgrade
              </Button>,
            ]}
          >
            <List.Item.Meta
              avatar={<Avatar src={item.blocks[item.blocks.length - 1].icon} />}
              title={<a href="https://ant.design">{item.name}</a>}
              description={`Current version: ${item.version}; Installed version: ${item.installedVersion}`}
            />
            {item.active ? <Tag color="blue">active</Tag> : null}
            {item.installed ? <Tag color="green">installed</Tag> : null}
          </List.Item>
        )}
      />
    </>
  );
};

import axios from "axios";
import CryptoJS from "crypto-js";

let axiosInstance = null;

export const initializeAxiosInstance = (password) => {
  axiosInstance = axios.create({
    baseURL: "https://embedded.runalloy.com/2023-01",
    headers: {
      Authorization: CryptoJS.AES.decrypt(
        process.env.REACT_APP_RUNALLOY_API_ENCRYPTED,
        password,
        { salt: process.env.REACT_APP_RUNALLOY_API_SALT }
      ).toString(CryptoJS.enc.Utf8),
    },
  });
};

export const getUsers = () => {
  return axiosInstance.get("/users");
};

export const getUser = (userId) => {
  return axiosInstance.get(`/users/${userId}`);
};

export const getUserWorkflows = (userId) => {
  return axiosInstance.get(`/workflows?userId=${userId}`);
};

export const upgradeWorfklow = (workflowId, userId) => {
  return axiosInstance.put(
    `https://embedded.runalloy.com/2023-01/workflows/${workflowId}/upgrade?userId=${userId}`
  );
};

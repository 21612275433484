import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { hash } from "../../utils/hash";
import { initializeAxiosInstance } from "../../api/runalloy";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  const login = useCallback(async (password) => {
    const hashedPassword = await hash(password);
    const expectedHashedPassword = process.env.REACT_APP_HASHED_PASSWORD;
    if (hashedPassword === expectedHashedPassword) {
      sessionStorage.setItem("password", password);
      setIsAuthenticated(true);
      initializeAxiosInstance(password);
      return true;
    }
    return false;
  }, []);

  useEffect(() => {
    const sessionPassword = sessionStorage.getItem("password");
    if (sessionPassword) {
      login(sessionPassword).then((ok) => {
        if (!ok) {
          sessionStorage.removeItem("password");
        }
        setIsInitialized(true);
      });
    } else {
      setIsInitialized(true);
    }
  }, [login]);

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, isInitialized }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };

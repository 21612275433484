import { useState } from "react";
import { Button, Form, Input } from "antd";
import { useAuth } from "../../contexts/AuthContext/AuthContext";
import { Navigate, useNavigate } from "react-router-dom";

export const LoginPage = () => {
  const { login, isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const [passwordError, setPasswordError] = useState(null);

  const onFinish = async (values) => {
    setPasswordError(null);
    const ok = await login(values.password);
    if (ok) {
      navigate("/");
    } else {
      setPasswordError("Incorrect password");
    }
  };

  if (isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  return (
    <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      style={{ maxWidth: 600, paddingTop: "100px", paddingBottom: "100px" }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item
        label="Password"
        name="password"
        rules={[{ required: true, message: "Please input your password!" }]}
        validateStatus={passwordError ? "error" : undefined}
        hasFeedback={passwordError ? true : undefined}
        help={passwordError ? passwordError : undefined}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

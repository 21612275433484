import { Navigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext/AuthContext";

export const AuthenticatedPage = ({ children }) => {
  const { isAuthenticated, isInitialized } = useAuth();

  if (!isInitialized) {
    return "loading...";
  }
  if (!isAuthenticated && isInitialized) {
    return <Navigate to="/login" replace />;
  }
  return children;
};

import React from "react";
import { Layout, Typography, theme } from "antd";

const { Header, Content, Footer } = Layout;

const ApplicationLayout = ({ children }) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <Layout className="layout">
      <Header style={{ display: "flex", alignItems: "center" }}>
        <div className="demo-logo" />
        <Typography.Title keyboard style={{ color: "white" }}>
          Runalloy
        </Typography.Title>
      </Header>
      <Content style={{ padding: "0 50px", minHeight: "calc(100vh - 120px)" }}>
        <div
          className="site-layout-content"
          style={{ background: colorBgContainer }}
        >
          {children}
        </div>
      </Content>
      <Footer style={{ textAlign: "center" }}>
        Created by Archive Technologies
      </Footer>
    </Layout>
  );
};

export { ApplicationLayout };

import React from "react";

import { useQuery } from "@tanstack/react-query";

import { List, Skeleton } from "antd";
import { Link } from "react-router-dom";
import { getUsers } from "../../api/runalloy";

const UsersPage = () => {
  const { isLoading, isError, data, error } = useQuery({
    queryKey: ["users"],
    queryFn: async () => {
      const { data } = await getUsers();

      return data;
    },
  });
  if (isLoading) return <Skeleton />;
  if (isError) return <div>{error.message}</div>;

  return (
    <List
      className="demo-loadmore-list"
      loading={isLoading}
      itemLayout="horizontal"
      dataSource={data.data}
      renderItem={(item) => (
        <List.Item
          actions={[
            <Link key={item.userId} to={`/users/${item.userId}`}>
              edit
            </Link>,
          ]}
        >
          <List.Item.Meta
            title={<a href="https://ant.design">{item.fullName}</a>}
            description={item.userId}
          />
        </List.Item>
      )}
    />
  );
};

export { UsersPage };

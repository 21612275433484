import "./App.css";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { UsersPage } from "./pages/UsersPage/UsersPage";
import { UserPage } from "./pages/UserPage/UserPage";
import { LoginPage } from "./pages/LoginPage/LoginPage";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ApplicationLayout } from "./components/ApplicationLayout/ApplicationLayout";
import { AuthenticatedPage } from "./components/AuthenticatedPage/AuthenticatedPage";
import { AuthProvider } from "./contexts/AuthContext/AuthContext";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <AuthenticatedPage>
        <ApplicationLayout>
          <UsersPage />
        </ApplicationLayout>
      </AuthenticatedPage>
    ),
  },
  {
    path: "/users/:userId",
    element: (
      <AuthenticatedPage>
        <ApplicationLayout>
          <UserPage />
        </ApplicationLayout>
      </AuthenticatedPage>
    ),
  },
  {
    path: "/login",
    element: (
      <ApplicationLayout>
        <LoginPage />
      </ApplicationLayout>
    ),
  },
]);

const queryClient = new QueryClient();

function App() {
  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </AuthProvider>
  );
}

export default App;
